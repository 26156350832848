<template>
  <div class="rounded border-all my-0 py-0">
    <div class="pt-0 px-4 pb-0">
      <v-row class="my-0 py-0">
        <v-col cols="4" class="mb-3">
          <v-text-field
            type="text"
            label="Ad Soyad"
            filled
            dense
            v-model="filters.fullName"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="mb-3">
          <v-autocomplete
            label="Departament"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            :items="departments"
            v-model="filters.departmentId"
          />
        </v-col>
        <v-col cols="4" class="mb-3">
          <v-autocomplete
            label="Vəzifə"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            :items="positions"
            v-model="filters.positionId"
          />
        </v-col>

        <v-col cols="4" class="py-0">
          <v-row class="my-0">
            <v-col cols="6">
              <v-text-field
                type="number"
                label="Yaş(dan)"
                filled
                dense
                v-model="filters.minAge"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="number"
                label="Yaş(a)"
                filled
                dense
                v-model="filters.maxAge"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4" class="mb-3">
          <v-select
            label="İş vaxtı"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            :items="workScheduleTypes"
            v-model="filters.workScheduleTypeId"
          />
        </v-col>

        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Vəzifə təsnifatı</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.employmentTypeId"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio
                    :label="employmentType.value"
                    :value="employmentType.id"
                    v-for="(employmentType, index) in employmentTypes"
                    :key="index"
                  ></v-radio>
                  <v-radio label="Hamısı" value="all" key="all"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Ştat tutumu</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.staffTypeId"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio
                    :label="staffType.value"
                    :value="staffType.id"
                    v-for="(staffType, index) in staffTypes"
                    :key="index"
                  ></v-radio>
                  <v-radio label="Hamısı" value="all" key="all"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Statusu</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.isArchived"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio label="Aktiv" value="false"></v-radio>
                  <v-radio label="Arxiv" value="true"></v-radio>
                  <v-radio label="Hamısı" value="null"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">İş yeri</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.workPlaceTypeId"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio
                    :label="workplaceType.value"
                    :value="workplaceType.id"
                    v-for="(workplaceType, index) in workplaceTypes"
                    :key="index"
                  ></v-radio>
                  <v-radio label="Hamısı" value="all" key="all"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Cinsiyyət</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.genderId"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio
                    :label="gender.value"
                    :value="gender.id"
                    v-for="(gender, index) in genders"
                    :key="index"
                  ></v-radio>
                  <v-radio label="Hamısı" value="all" key="all"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="py-3 px-4 d-flex justify-end border-top">
      <v-btn
        @click="clear"
        class="clear-filters mr-3"
        depressed
        color="gray lighten-3"
      >
        <span class="gray--text text--darken-3 text-body-2 font-weight-medium"
          >Təmizlə</span
        >
      </v-btn>
      <v-btn @click="search" color="primary" depressed> Axtar </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employmentTypes: {
      type: Array,
      required: true,
    },
    workplaceTypes: {
      type: Array,
      required: true,
    },
    staffTypes: {
      type: Array,
      required: true,
    },
    workScheduleTypes: {
      type: Array,
      required: true,
    },
    departments: {
      type: Array,
      required: true,
    },
    positions: {
      type: Array,
      required: true,
    },
    genders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        fullName: null,
        departmentId: null,
        positionId: null,
        minAge: null,
        maxAge: null,
        workScheduleTypeId: null,
        employmentTypeId: null,
        staffTypeId: null,
        isArchived: "false",
        workPlaceTypeId: null,
        genderId: null,
      },
      checkedFilters: {},
    };
  },
  methods: {
    search() {
      this.createFilterModel();

      this.$emit("search", this.checkedFilters);
    },
    clear() {
      this.filters = {
        fullName: null,
        departmentId: null,
        positionId: null,
        minAge: null,
        maxAge: null,
        workScheduleTypeId: null,
        employmentTypeId: null,
        staffTypeId: null,
        isArchived: "false",
        workPlaceTypeId: null,
        genderId: null,
      };
      this.createFilterModel();

      this.$emit("clear", this.checkedFilters);
    },
    createFilterModel() {
      this.checkedFilters = {};

      if (this.filters.fullName) {
        this.checkedFilters.fullName = this.filters.fullName;
      }

      if (this.filters.departmentId) {
        this.checkedFilters.departmentId = this.filters.departmentId;
      }

      if (this.filters.positionId) {
        this.checkedFilters.positionId = this.filters.positionId;
      }

      if (this.filters.minAge) {
        this.checkedFilters.minAge = this.filters.minAge;
      }

      if (this.filters.maxAge) {
        this.checkedFilters.maxAge = this.filters.maxAge;
      }

      if (this.filters.workScheduleTypeId) {
        this.checkedFilters.workScheduleTypeId = this.filters.workScheduleTypeId;
      }

      this.checkedFilters.isArchived = JSON.parse(this.filters.isArchived);

      if (
        this.filters.staffTypeId &&
        this.filters.staffTypeId.toString() !== "all"
      ) {
        this.checkedFilters.staffTypeId = this.filters.staffTypeId;
      }

      if (
        this.filters.workPlaceTypeId &&
        this.filters.workPlaceTypeId.toString() !== "all"
      ) {
        this.checkedFilters.workPlaceTypeId = this.filters.workPlaceTypeId;
      }

      if (this.filters.genderId && this.filters.genderId.toString() !== "all") {
        this.checkedFilters.genderId = this.filters.genderId;
      }
    },
  },
};
</script>
