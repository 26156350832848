<template>
  <v-container fluid class="down-top-padding">
    <div id="employeePage" class="page">
      <div class="page__header">
        <Toolbar @toggleFilters="handleToggleFilters" />
      </div>
      <div class="page__body pa-4">
        <div v-show="searchBoxdialog">
          <v-row class="my-0">
            <v-col md="12">
              <Filters
                :employmentTypes="employmentTypes"
                :workplaceTypes="workplaceTypes"
                :staffTypes="staffTypes"
                :workScheduleTypes="workScheduleTypes"
                :departments="departments"
                :positions="positions"
                :genders="genders"
                @search="filtersChanged"
                @clear="filtersChanged"
              />
            </v-col>
          </v-row>
        </div>

        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @optionsChanged="handleOptionsChanged"
          @toggleEmployeeArchiving="handletoggleEmployeeArchiving"
        />
      </div>
      <ConfirmationPopup
        id="employee-archive-modal"
        title="Əməkdaşın arxivləməsi"
        v-if="employeeToArchive"
        :dialog="confirmationDialog"
        @confirm="handleArchiveConfirmation(employeeToArchive.id)"
        @cancel="handleArchiveCancellation"
        :confirmText="
          employeeToArchive.isArchived ? 'Arxivdən çıxart' : 'Arxiv et'
        "
      >
        <span class="font-weight-bold">
          {{
            `"${employeeToArchive.firstName +
              " " +
              employeeToArchive.lastName}"`
          }}
        </span>
        haqqında məlumatları
        {{
          employeeToArchive.isArchived ? "arxivdən çıxartmağa" : "arxivləməyə"
        }}
        əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import List from "./components/List/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Filters/index.vue";
import filtersPopup from "@/mixins/filters-popup.js";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../event-bus";

export default {
  mixins: [filtersPopup],
  components: {
    List,
    Toolbar,
    Filters,
    ConfirmationPopup,
  },
  data() {
    return {
      confirmationDialog: false,
      employeeToArchive: null,
      searchBoxdialog: false,
      selectedUser: null,
      page: 1,
      isFetchingItems: false,
      totalCount: 0,
      itemsPerPage: 25,
      sortedBy: null,
      sortedDesc: null,
      items: [],
      employmentTypes: [],
      workplaceTypes: [],
      staffTypes: [],
      workScheduleTypes: [],
      departments: [],
      positions: [],
      genders: [],
      filters: null,
    };
  },
  async created() {
    this.fetchEmployees();
    this.fetchHRDepartments();
    this.fetchHRStaffTypes();
    this.fetchHRWorkScheduleTypes();
    this.fetchHREmploymentTypes();
    this.fetchHRWorkplaceTypes();
    this.fetchHRGenders();
    this.fetchAllPositions();
  },
  methods: {
    async fetchHRDepartments() {
      this.departments = await API.fetchHRDepartments();
    },
   
    async fetchHRStaffTypes() {
      this.staffTypes = await API.fetchHRStaffTypes();
    },
    async fetchHRWorkScheduleTypes() {
      this.workScheduleTypes = await API.fetchHRWorkScheduleTypes();
    },
    async fetchHREmploymentTypes() {
      this.employmentTypes = await API.fetchHREmploymentTypes();
    },
    async fetchHRWorkplaceTypes() {
      this.workplaceTypes = await API.fetchHRWorkplaceTypes();
    },
    async fetchHRGenders() {
      this.genders = await API.fetchHRGenders();
    },
    async fetchAllPositions() {
      this.positions = await API.fetchAllPositions();
    },
    getProfilePhotos() {
      this.items.forEach((item) => {
        let response = API.fetchHRProfilePhoto(item.id, 56, 0);
        response
          .then((res) => {
            this.$set(item, "profile", res);
            item.profile = res;
          })
          .catch(() => {
            item.profile = null;
          });
      });
    },
    fetchEmployees() {
      this.isFetchingItems = true;
      API.fetchHREmployees({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        sortedBy: this.sortedBy,
        sortedDesc: this.sortedDesc,
        isArchived: false,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
          this.getProfilePhotos();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməkdaşların siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleOptionsChanged(value) {
      if (typeof value.sortedBy === "undefined") {
        this.sortedBy = null;
      } else {
        this.sortedBy = value.sortedBy;
      }
      if (typeof value.sortedDesc === "undefined") {
        this.sortedDesc = null;
      } else {
        this.sortedDesc = value.sortedDesc;
      }

      this.itemsPerPage = value.itemsPerPage;
      this.page = value.page;
      this.fetchEmployees();
    },
    handleToggleFilters(value) {
      this.searchBoxdialog = value;
    },
    filtersChanged(filters) {
      if (JSON.stringify(this.filters) !== JSON.stringify(filters)) {
        this.filters = filters;
        this.page = 1;
        this.fetchEmployees();
      }
    },
    handletoggleEmployeeArchiving(item) {
      this.employeeToArchive = item;
      this.confirmationDialog = true;
    },
    handleArchiveConfirmation(id) {
      API.toggleEmployeeArchiving(id)
        .then(() => {
          bus.$emit("notificationMessage", {
            color: "success",
            text: this.employeeToArchive.isArchived
              ? "Əməkdaş arxivdən çıxarıldı."
              : "Əməkdaş arxiv olundu.",
          });
          this.fetchEmployees();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: this.employeeToArchive.isArchived
              ? "Əməkdaşı arxivdən çıxarılması mümkün olmadı."
              : "Əməkdaşın arxiv olunması mümkün olmadı.",
          });
        })
        .finally(() => {
          this.confirmationDialog = false;
          this.employeeToArchive = null;
        });
    },
    handleArchiveCancellation() {
      this.confirmationDialog = false;
      this.employeeToArchive = null;
    },
  },
};
</script>
