<template>
  <div class="py-3 px-4">
    <div class="d-flex justify-start align-start">
      <v-btn
        @click="toggleFiltersPopup"
        class="ml-auto mr-2"
        text
        depressed
        :class="[filtersPopup ? 'grey lighten-4' : 'white']"
      >
        <v-icon class="mr-2" medium>
          mdi-magnify
        </v-icon>
        <span>
          Axtarış
        </span>
      </v-btn>
      <v-btn :to="'employees/new'" color="primary" depressed>
        <v-icon class="mr-0" medium>
          mdi-plus
        </v-icon>
        <span>
          Əlavə et
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      filtersPopup: false,
    };
  },
  watch: {
    filtersPopup(value) {
      this.$emit("toggleFilters", value);
    },
  },
  methods: {
    toggleFiltersPopup() {
      this.filtersPopup = !this.filtersPopup;
    },
  },
};
</script>
