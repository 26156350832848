<template>
  <v-data-table
    :item-class="getItemClass"
    :page="page"
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :items-per-page="itemsPerPage"
    :server-items-length="totalCount"
    :options.sync="options"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
  >
    <template v-slot:[`item.firstName`]="{ item }">
      <div class="d-flex align-center">
        <v-avatar size="28" style="margin-right: 10px" v-if="item.profile">
          <img v-bind:src="item.profile" :alt="item.firstName" />
        </v-avatar>
        <v-avatar class="border-all" size="28" style="margin-right: 10px" v-else>
          <span class="caption">{{ item.firstName.charAt(0).toUpperCase() }}{{ item.lastName.charAt(0).toUpperCase() }}</span>
        </v-avatar>
        <span>
          {{
            item.firstName +
              ' ' +
              item.lastName +
              ' ' +
              item.middleName.charAt(0).toUpperCase() +
              '.'
          }}
        </span>
      </div>
    </template>
    <template v-slot:[`item.position`]="{ item }">
      <template v-if="item.position">{{ item.position }}</template>
      <template v-else> <span v-html="'&mdash;'"></span> </template>
    </template>
    <template v-slot:[`item.department`]="{ item }">
      <template v-if="item.department">{{ item.department }}</template>
      <template v-else> <span v-html="'&mdash;'"></span> </template>
    </template>
    <template v-slot:[`item.emailAddress`]="{ item }">
      <template v-if="item.emailAddress">{{ item.emailAddress }}</template>
      <template v-else> <span v-html="'&mdash;'"></span> </template>
    </template>
    <template v-slot:[`item.corporationPhoneNumber`]="{ item }">
      <template v-if="item.corporationPhoneNumber">{{ item.corporationPhoneNumber }}</template>
      <template v-else> <span v-html="'&mdash;'"></span> </template>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-end">
        <v-btn small icon :to="`/employees/${item.id}/view`">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-horizontal </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="!item.isArchived"
              :to="`/employees/${item.id}/edit`"
            >
              <v-list-item-title
                ><span class="body-2 gray--text text--darken-3">Redaktə et</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="toggleEmployeeArchiving(item)">
              <v-list-item-title
                ><span class="body-2 gray--text text--darken-3">{{
                  item.isArchived ? 'Arxivdən çıxart' : 'Arxiv et'
                }}</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isFetchingItems: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'A.S.A.', value: 'firstName', sortable: true },
        { text: 'Vəzifə', value: 'position', sortable: false },
        { text: 'Departament', value: 'department', sortable: true, width: '13%' },
        { text: 'E-ünvan', value: 'emailAddress', sortable: false },
        {
          text: 'Mobil nömrə',
          value: 'mobileNumber',
          sortable: false,
          width: '12%'
        },
        {
          text: 'Daxili nömrə',
          value: 'internalPhoneNumber',
          sortable: false,
          width: '12%'
        },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      options: {}
    }
  },
  watch: {
    options: {
      handler(value) {
        const { itemsPerPage, page, sortBy, sortDesc } = value
        this.$emit('optionsChanged', {
          sortedBy: sortBy[0],
          sortedDesc: sortDesc[0],
          itemsPerPage,
          page
        })
      },
      deep: true
    }
  },
  methods: {
    getItemClass(item) {
      return item.isArchived ? 'gray--text text--lighten-1' : ''
    },
    toggleEmployeeArchiving(item) {
      this.$emit('toggleEmployeeArchiving', item)
    }
  }
}
</script>
