import bus from "../../event-bus"

export default {
  data() {
    return {
      sidebarToggle: true
    }
  },
  created() {
    bus.$emit('toggleSidebar', this.handleSidebarToggle)
  },
  beforeDestroy() {
    bus.$emit('toggleSidebar', this.handleSidebarToggle)
  },
  computed: {
    filtersPopupClass() {
      return this.sidebarToggle ? 'filters-popup' : 'filters-popup full-width'
    }
  },
  methods: {
    handleSidebarToggle(sidebarToggle) {
      this.sidebarToggle = sidebarToggle
    }
  }
}
